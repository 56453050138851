import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  modal: {
    width: 460,
    backgroundColor: "#fff",
    borderRadius: 16,
    overflow: "hidden",
    boxShadow: "0 25px 50px rgba(0, 0, 0, 0.25)",
    position: "relative",
    animation: "$modalAppear 0.5s ease",
  },

  "@global": {
    "@keyframes modalAppear": {
      "0%": {
        opacity: 0,
        transform: "translateY(20px)",
      },
      "100%": {
        opacity: 1,
        transform: "translateY(0)",
      },
    },
  },

  closeButton: {
    position: "absolute",
    top: 12,
    right: 12,
    background: "transparent",
    border: "none",
    cursor: "pointer",
    color: "#999",
    padding: 8,
    zIndex: 10,
  },
  gallerySection: {
    position: "relative",
    height: 180,
    background: "linear-gradient(to right, #f8f9fa 0%, white 100%)",
    overflow: "hidden",
  },
  galleryImages: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    padding: 16,
  },
  imageContainer: {
    width: 1000,
    height: 150,
    position: "relative",
    borderRadius: 8,
    overflow: "hidden",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
    marginRight: 12,
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "rotate(0deg) translateY(-5px)",
    },
  },
  galleryImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  privateBadge: {
    position: "absolute",
    top: 8,
    right: 8,
    backgroundColor: "#ff3b5c",
    color: "white",
    fontSize: 10,
    fontWeight: 600,
    padding: "3px 6px",
    borderRadius: 4,
    zIndex: 1,
  },
  lockIcon: {
    position: "absolute",
    bottom: 8,
    right: 8,
    width: 28,
    height: 28,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    zIndex: 1,
  },
  galleryCover: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "35%",
    height: "100%",
    background:
      "linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 40%, white 100%)",
    zIndex: 2,
  },
  modalContent: {
    padding: 24,
  },
  modalHeader: {
    marginBottom: 20,
  },
  modalTitle: {
    fontSize: 22,
    fontWeight: 700,
    color: "#333",
    margin: "0 0 6px",
  },
  modalSubtitle: {
    fontSize: 15,
    color: "#666",
    margin: 0,
    lineHeight: 1.5,
  },
  statusIndicator: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff8f9",
    border: "1px solid #ffccd5",
    borderRadius: 10,
    padding: 10,
    margin: "0 16px 15px",
    gap: 8,
    justifyContent: "center",
  },
  statusIcon: {
    width: 28,
    height: 28,
    backgroundColor: "#ff3b5c",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    flexShrink: 0,
  },
  statusLabel: {
    fontSize: 14,
    color: "#333",
    fontWeight: 500,
  },
  statusBadge: {
    textAlign: "center",
    backgroundColor: "#ff3b5c",
    color: "white",
    fontSize: 12,
    fontWeight: 600,
    padding: "4px 12px",
    borderRadius: 20,
  },
  featuresList: {
    marginBottom: 28,
  },
  featureItem: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: 16,
  },
  featureIcon: {
    width: 28,
    height: 28,
    backgroundColor: "rgba(255,59,92,0.1)",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 12,
    color: "#ff3b5c",
    flexShrink: 0,
  },
  featureContent: {
    flex: 1,
  },
  featureTitle: {
    fontSize: 15,
    fontWeight: 600,
    color: "#333",
    margin: "0 0 4px",
  },
  featureDesc: {
    fontSize: 14,
    color: "#666",
    margin: 0,
    lineHeight: 1.4,
  },
  modalActions: {
    display: "flex",
    padding: "0 16px 16px",
    gap: 8,
  },
  btn: {
    flex: 1,
    padding: "14px 16px",
    borderRadius: 10,
    fontSize: 15,
    fontWeight: 600,
    border: "none",
    cursor: "pointer",
    transition: "all 0.2s ease",
    textAlign: "center",
  },
  btnCancel: {
    backgroundColor: "#333",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#444",
    },
  },
  btnUpgrade: {
    backgroundColor: "#ff3b5c",
    color: "#fff",
    boxShadow: "0 4px 12px rgba(255,59,92,0.25)",
    "&:hover": {
      backgroundColor: "#ff1a42",
      transform: "translateY(-2px)",
      boxShadow: "0 8px 16px rgba(255,59,92,0.3)",
    },
  },
}));
