import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()(() => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  modal: {
    width: 480,
    backgroundColor: "#fff",
    borderRadius: 16,
    overflow: "hidden",
    boxShadow: "0 25px 50px rgba(0, 0, 0, 0.25)",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: 12,
    right: 12,
    background: "transparent",
    border: "none",
    cursor: "pointer",
    color: "#999",
    padding: 6,
    zIndex: 10,
  },
  modalHeader: {
    padding: "20px 20px 10px",
    textAlign: "center",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  securityIcon: {
    position: "relative",
    width: 50,
    height: 50,
    marginRight: 15,
  },
  shield: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(135deg, #ff3b5c, #ff7676)",
    borderRadius: "50%",
    boxShadow: "0 5px 15px rgba(255, 59, 92, 0.2)",
  },
  lock: {
    position: "absolute",
    top: "60%",
    left: "50%",
    // The lock body
    width: 23,
    height: 22,
    backgroundColor: "#fff",
    borderRadius: 3,
    transform: "translate(-50%, -55%)",
    "&::before": {
      content: '""',
      position: "absolute",
      top: -12,
      left: 3,
      width: 17,
      height: 12,
      border: "3px solid #fff",
      borderBottom: "none",
      borderRadius: "8px 8px 0 0",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: "45%",
      left: "50%",
      width: 2,
      height: 6,
      backgroundColor: "#ff3b5c",
      borderRadius: 1,
      transform: "translateX(-50%)",
    },
  },
  headerText: {
    textAlign: "left",
    flex: 1,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 700,
    color: "#333",
    margin: "0 0 4px",
  },
  modalSubtitle: {
    fontSize: 14,
    color: "#666",
    margin: 0,
    lineHeight: 1.3,
  },
  privacyBenefits: {
    padding: "10px 20px",
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
  },
  benefitCard: {
    flex: "1 0 calc(50% - 10px)",
    minWidth: "calc(50% - 10px)",
    backgroundColor: "#f8f9fa",
    borderRadius: 10,
    padding: 12,
    display: "flex",
    alignItems: "flex-start",
  },
  benefitIcon: {
    width: 32,
    height: 32,
    backgroundColor: "rgba(255, 59, 92, 0.1)",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ff3b5c",
    marginRight: 10,
    flexShrink: 0,
  },
  benefitContent: {
    flex: 1,
  },
  benefitTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: "#333",
    margin: "0 0 3px",
  },
  benefitDesc: {
    fontSize: 12,
    color: "#666",
    margin: 0,
    lineHeight: 1.3,
  },
  privacyLevel: {
    display: "flex",
    backgroundColor: "#fff8f9",
    border: "1px solid #ffccd5",
    borderRadius: 10,
    padding: 12,
    margin: "0 20px 15px",
    gap: 12,
    alignItems: "center",
  },
  levelIcon: {
    width: 32,
    height: 32,
    backgroundColor: "#ff3b5c",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    flexShrink: 0,
  },
  levelContent: {
    flex: 1,
  },
  levelTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: "#333",
    margin: "0 0 2px",
  },
  levelDesc: {
    fontSize: 12,
    color: "#666",
    margin: 0,
    lineHeight: 1.3,
  },
  modalActions: {
    display: "flex",
    padding: "0 20px 20px",
    gap: 12,
  },
  btn: {
    flex: 1,
    padding: "12px 16px",
    borderRadius: 10,
    fontSize: 14,
    fontWeight: 600,
    border: "none",
    cursor: "pointer",
    transition: "all 0.2s ease",
    textAlign: "center",
  },
  btnCancel: {
    backgroundColor: "#333",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#444",
    },
  },
  btnUpgrade: {
    backgroundColor: "#ff3b5c",
    color: "#fff",
    boxShadow: "0 4px 12px rgba(255, 59, 92, 0.25)",
    "&:hover": {
      backgroundColor: "#ff1a42",
      transform: "translateY(-2px)",
      boxShadow: "0 8px 16px rgba(255, 59, 92, 0.3)",
    },
  },
}));
