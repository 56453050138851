"use client";

import React, { FC, MouseEvent } from "react";
import { useStyles } from "./styles";

interface PrivateViewPaywallProps {
  onClose: () => void;
  onUpgrade: () => void;
}
const PrivateViewPaywall: FC<PrivateViewPaywallProps> = ({
  onClose,
  onUpgrade,
}) => {
  const { classes } = useStyles();

  const stopPropagation = (e: MouseEvent) => e.stopPropagation();

  return (
    <div className={classes.overlay} onClick={onClose}>
      <div className={classes.modal} onClick={stopPropagation}>
        <button className={classes.closeButton} onClick={onClose}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <div className={classes.modalHeader}>
          <div className={classes.securityIcon}>
            <div className={classes.shield}></div>
            <div className={classes.lock}></div>
          </div>
          <div className={classes.headerText}>
            <h2 className={classes.modalTitle}>Subscription Required</h2>
            <p className={classes.modalSubtitle}>
              Upgrade to make your creations private and secure
            </p>
          </div>
        </div>
        <div className={classes.privacyBenefits}>
          <div className={classes.benefitCard}>
            <div className={classes.benefitIcon}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className={classes.benefitContent}>
              <h4 className={classes.benefitTitle}>
                Complete Privacy & Control
              </h4>
              <p className={classes.benefitDesc}>
                Lock down your gallery. Choose exactly who can see your images.
              </p>
            </div>
          </div>

          <div className={classes.benefitCard}>
            <div className={classes.benefitIcon}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 15V17M6 21H18C19.1046 21 20 20.1046 20 19V13C20 11.8954 19.1046 11 18 11H6C4.89543 11 4 11.8954 4 13V19C4 20.1046 4.89543 21 6 21ZM16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11H16Z"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className={classes.benefitContent}>
              <h4 className={classes.benefitTitle}>
                Protect Your Intellectual Property
              </h4>
              <p className={classes.benefitDesc}>
                Prevent unauthorized access to your ideas, perfect for client
                work or sensitive projects.
              </p>
            </div>
          </div>

          <div className={classes.benefitCard}>
            <div className={classes.benefitIcon}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 16L21 12M21 12L17 8M21 12H7M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className={classes.benefitContent}>
              <h4 className={classes.benefitTitle}>Selective Sharing</h4>
              <p className={classes.benefitDesc}>
                Share with specific people while keeping others private
              </p>
            </div>
          </div>

          <div className={classes.benefitCard}>
            <div className={classes.benefitIcon}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 14L4 9L9 4M15 4L20 9L15 14M17 20H7"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className={classes.benefitContent}>
              <h4 className={classes.benefitTitle}>Hidden Drafts</h4>
              <p className={classes.benefitDesc}>
                Keep work-in-progress images private until you’re ready to
                reveal them.
              </p>
            </div>
          </div>
        </div>

        {/* Extra privacy level info */}
        <div className={classes.privacyLevel}>
          <div className={classes.levelIcon}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 15V17M6 21H18C19.1046 21 20 20.1046 20 19V13C20 11.8954 19.1046 11 18 11H6C4.89543 11 4 11.8954 4 13V19C4 20.1046 4.89543 21 6 21ZM16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11H16Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className={classes.levelContent}>
            <h4 className={classes.levelTitle}>Premium Privacy Protection</h4>
            <p className={classes.levelDesc}>
              Your gallery is currently public. Upgrade now to keep everything
              confidential by default.
            </p>
          </div>
        </div>

        {/* Footer actions */}
        <div className={classes.modalActions}>
          <button
            className={`${classes.btn} ${classes.btnCancel}`}
            onClick={onClose}
          >
            CANCEL
          </button>
          <button
            className={`${classes.btn} ${classes.btnUpgrade}`}
            onClick={onUpgrade}
          >
            UPGRADE NOW
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivateViewPaywall;
