"use client";

import React, { FC } from "react";
import { useRouter } from "next/navigation";
import { useStyles } from "./styles";

import CollectionsIcon from "@mui/icons-material/Collections";
import ShareIcon from "@mui/icons-material/Share";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PublicIcon from "@mui/icons-material/Public";

interface PrivateCreationsModalProps {
  onClose: () => void;
  onUpgrade: () => void;
}

const PrivateCreationsModal: FC<PrivateCreationsModalProps> = ({
  onClose,
  onUpgrade,
}) => {
  const { classes } = useStyles();
  const router = useRouter();

  const handleCancel = () => {
    onClose();
  };

  const handleUpgrade = () => {
    onUpgrade();
  };

  return (
    <div className={classes.modalOverlay} onClick={handleCancel}>
      <div className={classes.modal} onClick={(e) => e.stopPropagation()}>
        {/* Close Button */}
        <button className={classes.closeButton} onClick={handleCancel}>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

        <div className={classes.gallerySection}>
          <div className={classes.galleryImages}>
            <div className={classes.imageContainer}>
              <img
                className={classes.galleryImage}
                src="https://files.idyllic.app/files/static/5117378?width=640&optimizer=image"
                alt="AI Generated Artwork"
              />
              <div className={classes.privateBadge}>PRIVATE</div>
              <div className={classes.lockIcon}>
                <LockOutlinedIcon fontSize="small" />
              </div>
            </div>
            <div className={classes.imageContainer}>
              <img
                className={classes.galleryImage}
                src="https://files.idyllic.app/files/static/5117357?width=640&optimizer=image"
                alt="AI Generated Landscape"
              />
              <div className={classes.privateBadge}>PRIVATE</div>
              <div className={classes.lockIcon}>
                <LockOutlinedIcon fontSize="small" />
              </div>
            </div>
            <div className={classes.imageContainer}>
              <img
                className={classes.galleryImage}
                src="https://files.idyllic.app/files/static/5117393?width=1200&optimizer=image"
                alt="AI Generated Abstract Art"
              />
              <div className={classes.privateBadge}>PRIVATE</div>
              <div className={classes.lockIcon}>
                <LockOutlinedIcon fontSize="small" />
              </div>
            </div>
          </div>
          <div className={classes.galleryCover} />
        </div>

        {/* Modal Content */}
        <div className={classes.modalContent}>
          <div className={classes.modalHeader}>
            <h2 className={classes.modalTitle}>Subscription Required</h2>
            <p className={classes.modalSubtitle}>
              Upgrade to make your creations private
            </p>
          </div>

          <div className={classes.statusIndicator}>
            <div className={classes.statusIcon}>
              <PublicIcon fontSize="small" />
            </div>
            <span className={classes.statusLabel}>
              Your gallery is currently public
            </span>
            <span className={classes.statusBadge}>Upgrade for Privacy</span>
          </div>
          <div className={classes.featuresList}>
            <div className={classes.featureItem}>
              <div className={classes.featureIcon}>
                <CollectionsIcon fontSize="small" />
              </div>
              <div className={classes.featureContent}>
                <h4 className={classes.featureTitle}>
                  Private Gallery Collection
                </h4>
                <p className={classes.featureDesc}>
                  Keep your creative work hidden from the public eye. Perfect
                  for professionals or personal projects that need
                  confidentiality.
                </p>
              </div>
            </div>

            <div className={classes.featureItem}>
              <div className={classes.featureIcon}>
                <ShareIcon fontSize="small" />
              </div>
              <div className={classes.featureContent}>
                <h4 className={classes.featureTitle}>Controlled Sharing</h4>
                <p className={classes.featureDesc}>
                  Share images only with chosen collaborators, no more unwanted
                  viewers.
                </p>
              </div>
            </div>
          </div>

          <div className={classes.modalActions}>
            <button
              className={`${classes.btn} ${classes.btnCancel}`}
              onClick={handleCancel}
            >
              CANCEL
            </button>
            <button
              className={`${classes.btn} ${classes.btnUpgrade}`}
              onClick={handleUpgrade}
            >
              UPGRADE NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateCreationsModal;
