"use client";

import { FC, useState, useEffect } from "react";
import { useRouter } from "next/navigation";
import { useAppSelector, useAppDispatch } from "@/redux/store";
import { setViewMode, updateGenerationViewMode } from "@/redux/actions";

import { fetchCtaVersions, postCtaView, postCtaConversion } from "utils/cta";
import { ViewMode } from "types/thread";
import ControlButton from "components/idea/GenInputs/Controls/ControlButton";
import PublicIcon from "components/icons/Public";
import PrivateIcon from "components/icons/PublicOff";

import PrivateViewPaywallA from "components/common/PaywallCTA/PrivateViewPaywall/PaywallA";
import PrivateViewPaywallB from "components/common/PaywallCTA/PrivateViewPaywall/PaywallB";

const ViewModeToggle: FC<{
  generationViewMode?: ViewMode;
  generationId?: number;
  scale?: number;
}> = ({ generationViewMode, generationId, scale = 1 }) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const user = useAppSelector((state) => state.user.user);
  const isSubscribed = Boolean(user && user.subscriptionType !== "free");
  const globalViewMode = useAppSelector((state) => state.room.viewMode);
  const [displayedViewMode, setDisplayedViewMode] = useState<ViewMode>(
    generationViewMode || globalViewMode
  );
  const [showModal, setShowModal] = useState(false);
  const [ctaVersion, setCtaVersion] = useState("A");

  const isDisabled = displayedViewMode === ViewMode.Restricted;

  useEffect(() => {
    if (!isSubscribed) {
      fetchCtaVersions("make_private_on_click", setCtaVersion);
    }
  }, [isSubscribed]);

  const handleUpgrade = async () => {
    setShowModal(false);
    await postCtaConversion("make_private_on_click", ctaVersion);
    router.push("/subscribe");
  };

  const handleToggleViewMode = async () => {
    if (isDisabled) return;

    if (!isSubscribed) {
      setShowModal(true);
      await postCtaView("make_private_on_click", ctaVersion);
      return;
    }

    const oldViewMode = displayedViewMode;
    const newViewMode =
      displayedViewMode === ViewMode.Public
        ? ViewMode.Private
        : ViewMode.Public;

    setDisplayedViewMode(newViewMode);

    if (generationId) {
      const success = await dispatch(
        updateGenerationViewMode({
          generationId,
          viewMode: newViewMode,
        })
      ).unwrap();

      if (!success) {
        setDisplayedViewMode(oldViewMode);
      }
    } else {
      dispatch(setViewMode(newViewMode));
    }
  };

  if (displayedViewMode === ViewMode.Restricted) {
    return null;
  }

  return (
    <>
      <ControlButton
        label="HD Toggle"
        icon={<PublicIcon />}
        activeIcon={<PrivateIcon />}
        disabled={!isSubscribed}
        onClick={handleToggleViewMode}
        isActive={displayedViewMode === ViewMode.Private}
        tooltip={
          displayedViewMode === ViewMode.Public ? "Make Private" : "Make Public"
        }
        scale={scale}
      />

      {showModal &&
        (ctaVersion === "A" ? (
          <PrivateViewPaywallA
            onClose={() => setShowModal(false)}
            onUpgrade={handleUpgrade}
          />
        ) : (
          <PrivateViewPaywallB
            onClose={() => setShowModal(false)}
            onUpgrade={handleUpgrade}
          />
        ))}
    </>
  );
};

export default ViewModeToggle;
